<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="`Historial de transacciones (${transactions[0]?.currency})`" />

    <!-- Information about wallet -->
    <div class="mt-4">
      <div class="secondary pa-4">
        <p class="mt-4">
          <b>Inversor:</b> <router-link :to="`/inversores/${$route.params.type}/${$route.params.id}`">{{ $route.params.id }}</router-link>
        </p>
      </div>
    </div>

    <!-- Data table transactions -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="transactions ? transactions : []"
        :sortDesc="false"
        :hideDefaultFooter="false"
        :disablePagination="false"
      >
        <template v-slot:[`item.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateLWithTime(item.created_at) }}
        </template>
        <template v-slot:[`item.consolidated_at`]="{ item }">
          {{ formatDateLWithTime(item.consolidated_at) }}
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>

<script>
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";
import * as currency from "currency.js";

export default {
  name: "Wallet",
  components: {
    Banner,
    DataTable
  },
  mixins: [FormatDateMixin],
  data() {
    return {
      transactions: [],

      // Transactions datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Tipo", value: "type", sortable: false },
        { text: "Cantidad", value: "amount", sortable: false },
        { text: "Fecha consolidado", value: "consolidated_at", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ]
    };
  },
  computed: {
    _() {
      return _;
    },
    currency() {
      return currency;
    }
  },
  async created() {
    const response = await this.$store.dispatch("investors/getWalletTransactions", this.$route.params.wallet_id);
    this.transactions = this._.values(response);
  }
};
</script>
